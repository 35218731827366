import PropTypes from "prop-types";
import React from "react";

import { ApiErrorTooManyRequests } from "lib/errors";

function hideDashboardBtn(error) {
  return error instanceof ApiErrorTooManyRequests;
}

function DashboardBtn({ error }) {
  if (hideDashboardBtn(error)) {
    return null;
  }

  return (
    <>
      <p>Click the button below to go to your dashboard</p>
      <div className="button-row">
        <a className="k-button" href="/">
          Go to dashboard
        </a>
      </div>
    </>
  );
}

DashboardBtn.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export default DashboardBtn;
