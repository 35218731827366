import React, { useContext, useMemo } from "react";
import {
  Navigate,
  Routes,
  UNSAFE_RouteContext as RouteContext,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export function Redirect({ to }) {
  return <Navigate to={to} replace />;
}

export function withRouterProps(Component) {
  return (props) => <Component location={useLocation()} navigate={useNavigate()} params={useParams()} {...props} />;
}

// This component uses an internal API of 'react-router-dom'. More info in 'README.md'.
export function AbsoluteRoutes(props) {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx],
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
}
