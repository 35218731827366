import enums from "./enums";

const permissions = {
  READ_CAMPAIGN_BUDGET_LIMITS: "read_campaign_budget_limits",
  READ_CAMPAIGN_RATE_CARD: "read_campaign_rate_card",

  UPDATE_RTB_ADVERTISER_STATUS: "update_rtb_advertiser_status",
  UPDATE_RTB_CAMPAIGN_STATUS: "update_rtb_campaign_status",
  UPDATE_HELP_ENTRIES: "update_help_entries",

  AGGREGATED_REPORTS_PERFORMANCE: "aggregated_reports_performance",
  AGGREGATED_REPORTS_BRANDING: "aggregated_reports_branding",
  AGGREGATED_REPORTS_CUSTOM_TIMEZONE: "aggregated_reports_custom_timezone",

  SHOW_HOUR_COLUMN: "show_hour_column",
  SHOW_CAMPAIGN_COLUMN: "show_campaign_column",
  SHOW_USER_SEGMENT_COLUMN: "show_user_segment_column",
  SHOW_DEVICE_TYPE_COLUMN: "show_device_type_column",
  SHOW_CREATIVE_COLUMN: "show_creative_column",
  SHOW_CATEGORY_COLUMN: "show_category_column",
  SHOW_COUNTRY_COLUMN: "show_country_column",

  SHOW_CAMPAIGN_COST_COLUMN: "show_campaign_cost_column",
  SHOW_IMPS_COUNT_COLUMN: "show_imps_count_column",
  SHOW_ECPM_COLUMN: "show_ecpm_column",
  SHOW_CLICKS_COUNT_COLUMN: "show_clicks_count_column",
  SHOW_LANDEDCLICKS_COUNT_COLUMN: "show_landedclicks_count_column",
  SHOW_ECPC_COLUMN: "show_ecpc_column",
  SHOW_CTR_COLUMN: "show_ctr_column",
  SHOW_CONVERSIONS_COUNT_COLUMN: "show_conversions_count_column",
  SHOW_ECPA_COLUMN: "show_ecpa_column",
  SHOW_CR_COLUMN: "show_cr_column",
  SHOW_CONVERSIONS_VALUE_COLUMN: "show_conversions_value_column",
  SHOW_ROAS_COLUMN: "show_roas_column",
  SHOW_ECPS_COLUMN: "show_ecps_column",
  SHOW_VIDEO_COMPLETE_VIEWS_COLUMN: "show_video_complete_views_column",
  SHOW_ECPV_COLUMN: "show_ecpv_column",
  SHOW_VCR_COLUMN: "show_vcr_column",
  SHOW_VIEWABILITY_COLUMN: "show_viewability_column",
  SHOW_VISITS_COUNT_COLUMN: "show_visits_count_column",
  SHOW_CPVISIT_COLUMN: "show_cpvisit_column",
  SHOW_USER_FREQUENCY_COLUMN: "show_user_frequency_column",
  SHOW_USER_REACH_COLUMN: "show_user_reach_column",

  FORCE_DISABLE_MFA: "force_disable_mfa",
};

export const metricsPermissions = {
  [enums.metrics.CAMPAIGN_COST]: permissions.SHOW_CAMPAIGN_COST_COLUMN,
  [enums.metrics.IMPS_COUNT]: permissions.SHOW_IMPS_COUNT_COLUMN,
  [enums.metrics.ECPM]: permissions.SHOW_ECPM_COLUMN,
  [enums.metrics.CLICKS_COUNT]: permissions.SHOW_CLICKS_COUNT_COLUMN,
  [enums.metrics.LANDEDCLICKS_COUNT]: permissions.SHOW_LANDEDCLICKS_COUNT_COLUMN,
  [enums.metrics.ECPC]: permissions.SHOW_ECPC_COLUMN,
  [enums.metrics.CTR]: permissions.SHOW_CTR_COLUMN,
  [enums.metrics.CONVERSIONS_COUNT]: permissions.SHOW_CONVERSIONS_COUNT_COLUMN,
  [enums.metrics.ECPA]: permissions.SHOW_ECPA_COLUMN,
  [enums.metrics.CR]: permissions.SHOW_CR_COLUMN,
  [enums.metrics.CONVERSIONS_VALUE]: permissions.SHOW_CONVERSIONS_VALUE_COLUMN,
  [enums.metrics.ROAS]: permissions.SHOW_ROAS_COLUMN,
  [enums.metrics.ECPS]: permissions.SHOW_ECPS_COLUMN,
  [enums.metrics.VIDEO_COMPLETE_VIEWS]: permissions.SHOW_VIDEO_COMPLETE_VIEWS_COLUMN,
  [enums.metrics.ECPV]: permissions.SHOW_ECPV_COLUMN,
  [enums.metrics.VCR]: permissions.SHOW_VCR_COLUMN,
  [enums.metrics.VIEWABILITY]: permissions.SHOW_VIEWABILITY_COLUMN,
  [enums.metrics.VISITS_COUNT]: permissions.SHOW_VISITS_COUNT_COLUMN,
  [enums.metrics.CPVISIT]: permissions.SHOW_CPVISIT_COLUMN,
  [enums.metrics.USER_FREQUENCY]: permissions.SHOW_USER_FREQUENCY_COLUMN,
  [enums.metrics.USER_REACH]: permissions.SHOW_USER_REACH_COLUMN,
};

export const conversionsEnabledPermissions = [
  permissions.SHOW_CONVERSIONS_COUNT_COLUMN,
  permissions.SHOW_CR_COLUMN,
  permissions.SHOW_ECPA_COLUMN,
  permissions.SHOW_ROAS_COLUMN,
  permissions.SHOW_ECPS_COLUMN,
  permissions.SHOW_CONVERSIONS_VALUE_COLUMN,
];

export default permissions;
