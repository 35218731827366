import { url } from "@rtbhouse-apps/commonlib";
import { config } from "@rtbhouse-apps/commonlib/dist/config-repo";
import { Api, JsonApi, TextApi } from "@rtbhouse-apps/rest-api-client";
import _ from "lodash";

import SystemActions from "./actions/SystemActions";

const dateFields = [
  "createdAt",
  "updatedAt",
  "deletedAt",
  "acceptedAt",
  "claimedAt",
  "invitationSentAt",
  "startDate",
  "endDate",
  "time",
];

const api = new Api(config);
const jsonApi = new JsonApi(api);
const textApi = new TextApi(api);

function prepareQueryParams(queryParams) {
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      if (value === undefined || value === null) {
        delete queryParams[key];
      }
    }
  }

  return queryParams;
}

function parseDates(obj) {
  if (!_.some(_.keys(obj), (key) => dateFields.includes(key))) {
    return obj;
  }

  return _.mapValues(obj, (value, key) => {
    if (dateFields.includes(key)) {
      return _.isEmpty(value) ? value : new Date(value);
    }

    return value;
  });
}

function serializeDates(obj) {
  if (!_.some(_.keys(obj), (key) => _.isDate(key))) {
    return obj;
  }
  return _.mapValues(obj, (value, key) => (_.isDate(key) ? value.toISOString() : value));
}

function prepareRequestData(data) {
  return _.isArray(data) ? data.map(serializeDates) : serializeDates(data);
}

async function normalizeResponseData(data) {
  return _.isArray(data) ? data.map(parseDates) : parseDates(data);
}

export default {
  api,
  textApi,

  async get(requestUrl, queryParams) {
    try {
      SystemActions.changePendingRequestsCount(1);
      const resData = await jsonApi.get(requestUrl, prepareQueryParams(queryParams));
      return await normalizeResponseData(resData);
    } finally {
      SystemActions.changePendingRequestsCount(-1);
    }
  },

  async put(requestUrl, data, queryParams) {
    try {
      SystemActions.changePendingRequestsCount(1);
      const resData = await jsonApi.put(requestUrl, prepareRequestData(data), prepareQueryParams(queryParams));
      return await normalizeResponseData(resData);
    } finally {
      SystemActions.changePendingRequestsCount(-1);
    }
  },

  async post(requestUrl, data, queryParams) {
    try {
      SystemActions.changePendingRequestsCount(1);
      const resData = await jsonApi.post(requestUrl, prepareRequestData(data), prepareQueryParams(queryParams));
      return await normalizeResponseData(resData);
    } finally {
      SystemActions.changePendingRequestsCount(-1);
    }
  },

  async delete(requestUrl, data, queryParams) {
    try {
      SystemActions.changePendingRequestsCount(1);
      const resData = await jsonApi.delete(requestUrl, prepareRequestData(data), prepareQueryParams(queryParams));
      return await normalizeResponseData(resData);
    } finally {
      SystemActions.changePendingRequestsCount(-1);
    }
  },

  download(endpoint, queryParams) {
    const queryString = !_.isEmpty(queryParams) ? `?${url.formatQueryString(queryParams)}` : "";
    window.open(api.buildUrl(endpoint) + queryString);
    return null;
  },
};
