const advertiserStatus = {
  NEW: "new",
  ACTIVE: "active",
  READY: "ready",
  PAUSED: "paused",
};

export enum CampaignStatus {
  ACTIVE = "Active",
  PAUSED = "Paused",
}

const campaignTypes = {
  PERFORMANCE: "PERFORMANCE",
  BRANDING: "BRANDING",
};

const adsetPlacements = {
  MOBILE: "Mobile feed",
  DESKTOP: "Desktop feed",
  DESKTOP_RIGHT_HAND_COLUMN: "Desktop right-hand column",
  OTHER: "Other (inc. Instagram)",
};

const deviceTypes = {
  PC: "PC",
  PHONE: "Phone",
  TABLET: "Tablet",
  MOBILE: "Mobile",
  TV: "TV",
  GAME_CONSOLE: "Game console",
  OTHER: "Other",
  UNKNOWN: "Unknown",
};

const appCodes = {
  INVALID_DATE_RANGE: "INVALID_DATE_RANGE",
  INVALID_PARAMETERS: "INVALID_PARAMETERS",
  EXPIRED_RESET_TOKEN: "EXPIRED_RESET_TOKEN",
  MAX_INVALID_2FA_USAGE_EXCEEDED: "MAX_INVALID_2FA_USAGE_EXCEEDED",
  PASSWORD_IS_NOT_SECURE: "PASSWORD_IS_NOT_SECURE",
};

const momentDateFormat = "YYYY-MM-DD HH:mm:ss";

const colors = {
  rtb: {
    orange: "#F2711C",
    red: "#EF4138",
    darkPurple: "#432844",
    purple: "#7C4A7D",
    lightPurple: "#D7C8D8",
    green: "#8BC34A",
    gray: "#CCCCCC",
    lightGray: "#EBEBEB",
    xLightGray: "#FAFAFA",
  },
};

const defaultConversionsCountConvention = {
  RTB: [
    { value: "ATTRIBUTED", text: "Attributed post click" },
    { value: "ALL_POST_CLICK", text: "All post click" },
    { value: "POST_VIEW", text: "Post view" },
    { value: "ALL_CONVERSIONS", text: "All conversions" },
  ],
};

const userSegmentOptions = {
  VISITORS: "Visitors",
  SHOPPERS: "Shoppers",
  BUYERS: "Buyers",
  NEW: "New",
};

const topHostsRankedByOptions = {
  CLICKS: "Number of clicks",
  IMPS: "Number of impressions",
};

const userSegmentColors = {
  VISITORS: colors.rtb.purple,
  SHOPPERS: colors.rtb.red,
  BUYERS: colors.rtb.gray,
  NEW: colors.rtb.lightPurple,
};

const panelFeatures = {
  // "group by" columns
  SHOW_HOUR_COLUMN: "SHOW_HOUR_COLUMN",
  SHOW_CAMPAIGN_COLUMN: "SHOW_CAMPAIGN_COLUMN",
  SHOW_USER_SEGMENT_COLUMN: "SHOW_USER_SEGMENT_COLUMN",
  SHOW_DEVICE_TYPE_COLUMN: "SHOW_DEVICE_TYPE_COLUMN",
  SHOW_CREATIVE_COLUMN: "SHOW_CREATIVE_COLUMN",
  SHOW_CATEGORY_COLUMN: "SHOW_CATEGORY_COLUMN",
  SHOW_COUNTRY_COLUMN: "SHOW_COUNTRY_COLUMN",

  // "metric" columns
  SHOW_CAMPAIGN_COST_COLUMN: "SHOW_CAMPAIGN_COST_COLUMN",
  SHOW_IMPS_COUNT_COLUMN: "SHOW_IMPS_COUNT_COLUMN",
  SHOW_ECPM_COLUMN: "SHOW_ECPM_COLUMN",
  SHOW_CLICKS_COUNT_COLUMN: "SHOW_CLICKS_COUNT_COLUMN",
  SHOW_LANDEDCLICKS_COUNT_COLUMN: "SHOW_LANDEDCLICKS_COUNT_COLUMN",
  SHOW_ECPC_COLUMN: "SHOW_ECPC_COLUMN",
  SHOW_CTR_COLUMN: "SHOW_CTR_COLUMN",
  SHOW_CONVERSIONS_COUNT_COLUMN: "SHOW_CONVERSIONS_COUNT_COLUMN",
  SHOW_ECPA_COLUMN: "SHOW_ECPA_COLUMN",
  SHOW_CR_COLUMN: "SHOW_CR_COLUMN",
  SHOW_CONVERSIONS_VALUE_COLUMN: "SHOW_CONVERSIONS_VALUE_COLUMN",
  SHOW_ROAS_COLUMN: "SHOW_ROAS_COLUMN",
  SHOW_ECPS_COLUMN: "SHOW_ECPS_COLUMN",
  SHOW_VIDEO_COMPLETE_VIEWS_COLUMN: "SHOW_VIDEO_COMPLETE_VIEWS_COLUMN",
  SHOW_ECPV_COLUMN: "SHOW_ECPV_COLUMN",
  SHOW_VCR_COLUMN: "SHOW_VCR_COLUMN",
  SHOW_VIEWABILITY_COLUMN: "SHOW_VIEWABILITY_COLUMN",
  SHOW_VISITS_COUNT_COLUMN: "SHOW_VISITS_COUNT_COLUMN",
  SHOW_CPVISIT_COLUMN: "SHOW_CPVISIT_COLUMN",
  SHOW_USER_FREQUENCY_COLUMN: "SHOW_USER_FREQUENCY_COLUMN",
  SHOW_USER_REACH_COLUMN: "SHOW_USER_REACH_COLUMN",

  // reports
  SHOW_DEDUPLICATION_REPORT: "SHOW_DEDUPLICATION_REPORT",
  SHOW_TOP_HOSTS_REPORT: "SHOW_TOP_HOSTS_REPORT",
  SHOW_TOP_IN_APPS_REPORT: "SHOW_TOP_IN_APPS_REPORT",
  SHOW_LAST_SEEN_TAGS_REPORT: "SHOW_LAST_SEEN_TAGS_REPORT",
  SHOW_WIN_RATE_REPORT: "SHOW_WIN_RATE_REPORT",
  SHOW_PRIVACY_SANDBOX_REPORT: "SHOW_PRIVACY_SANDBOX_REPORT",

  // other
  SHOW_CONVERSION_CLASS_COLUMN: "SHOW_CONVERSION_CLASS_COLUMN",
  SHOW_CONVERSION_COMMISSION_COLUMN: "SHOW_CONVERSION_COMMISSION_COLUMN",
  HIDE_USER_SEGMENT_SETTINGS: "HIDE_USER_SEGMENT_SETTINGS",
  ENABLE_CUSTOM_TIMEZONE: "ENABLE_CUSTOM_TIMEZONE",
};

const conversionsFeatures = [
  panelFeatures.SHOW_CONVERSIONS_COUNT_COLUMN,
  panelFeatures.SHOW_CR_COLUMN,
  panelFeatures.SHOW_ECPA_COLUMN,
  panelFeatures.SHOW_ROAS_COLUMN,
  panelFeatures.SHOW_ECPS_COLUMN,
  panelFeatures.SHOW_CONVERSIONS_VALUE_COLUMN,
];

const metrics = {
  CAMPAIGN_COST: "campaignCost",
  IMPS_COUNT: "impsCount",
  ECPM: "ecpm",
  CLICKS_COUNT: "clicksCount",
  LANDEDCLICKS_COUNT: "landedclicksCount",
  ECPC: "ecpc",
  CTR: "ctr",
  CONVERSIONS_COUNT: "conversionsCount",
  ECPA: "ecpa",
  CR: "cr",
  CONVERSIONS_VALUE: "conversionsValue",
  ROAS: "roas",
  ECPS: "ecps",
  VIDEO_COMPLETE_VIEWS: "videoCompleteViews",
  ECPV: "ecpv",
  VCR: "vcr",
  VIEWABILITY: "viewability",
  VISITS_COUNT: "visitsCount",
  CPVISIT: "cpvisit",
  USER_FREQUENCY: "userFrequency",
  USER_REACH: "userReach",
};

const metricsByCampaignType = {
  [campaignTypes.BRANDING]: [
    metrics.IMPS_COUNT,
    metrics.CAMPAIGN_COST,
    metrics.ECPM,
    metrics.CLICKS_COUNT,
    metrics.LANDEDCLICKS_COUNT,
    metrics.ECPC,
    metrics.CTR,
    metrics.CONVERSIONS_COUNT,
    metrics.USER_REACH,
    metrics.USER_FREQUENCY,
    metrics.VIEWABILITY,
    metrics.VISITS_COUNT,
    metrics.CPVISIT,
    metrics.VCR,
    metrics.ECPV,
    metrics.VIDEO_COMPLETE_VIEWS,
  ],
  [campaignTypes.PERFORMANCE]: [
    metrics.IMPS_COUNT,
    metrics.CAMPAIGN_COST,
    metrics.ECPM,
    metrics.CLICKS_COUNT,
    metrics.LANDEDCLICKS_COUNT,
    metrics.ECPC,
    metrics.CTR,
    metrics.CONVERSIONS_COUNT,
    metrics.VISITS_COUNT,
    metrics.CPVISIT,
    metrics.CR,
    metrics.CONVERSIONS_VALUE,
    metrics.ECPA,
    metrics.ECPS,
    metrics.ROAS,
  ],
};

const nonAdditiveMetrics = [
  metrics.USER_FREQUENCY,
  metrics.USER_REACH,
  metrics.VCR,
  metrics.VIEWABILITY,
  metrics.VISITS_COUNT,
  metrics.CPVISIT,
];

const conversionsRelatedMetrics = [
  metrics.CONVERSIONS_COUNT,
  metrics.ECPA,
  metrics.CR,
  metrics.CONVERSIONS_VALUE,
  metrics.ROAS,
  metrics.ECPS,
];

const metricsFeatures = {
  [metrics.CAMPAIGN_COST]: panelFeatures.SHOW_CAMPAIGN_COST_COLUMN,
  [metrics.IMPS_COUNT]: panelFeatures.SHOW_IMPS_COUNT_COLUMN,
  [metrics.ECPM]: panelFeatures.SHOW_ECPM_COLUMN,
  [metrics.CLICKS_COUNT]: panelFeatures.SHOW_CLICKS_COUNT_COLUMN,
  [metrics.LANDEDCLICKS_COUNT]: panelFeatures.SHOW_LANDEDCLICKS_COUNT_COLUMN,
  [metrics.ECPC]: panelFeatures.SHOW_ECPC_COLUMN,
  [metrics.CTR]: panelFeatures.SHOW_CTR_COLUMN,
  [metrics.CONVERSIONS_COUNT]: panelFeatures.SHOW_CONVERSIONS_COUNT_COLUMN,
  [metrics.ECPA]: panelFeatures.SHOW_ECPA_COLUMN,
  [metrics.CR]: panelFeatures.SHOW_CR_COLUMN,
  [metrics.CONVERSIONS_VALUE]: panelFeatures.SHOW_CONVERSIONS_VALUE_COLUMN,
  [metrics.ROAS]: panelFeatures.SHOW_ROAS_COLUMN,
  [metrics.ECPS]: panelFeatures.SHOW_ECPS_COLUMN,
  [metrics.VIDEO_COMPLETE_VIEWS]: panelFeatures.SHOW_VIDEO_COMPLETE_VIEWS_COLUMN,
  [metrics.ECPV]: panelFeatures.SHOW_ECPV_COLUMN,
  [metrics.VCR]: panelFeatures.SHOW_VCR_COLUMN,
  [metrics.VIEWABILITY]: panelFeatures.SHOW_VIEWABILITY_COLUMN,
  [metrics.VISITS_COUNT]: panelFeatures.SHOW_VISITS_COUNT_COLUMN,
  [metrics.CPVISIT]: panelFeatures.SHOW_CPVISIT_COLUMN,
  [metrics.USER_FREQUENCY]: panelFeatures.SHOW_USER_FREQUENCY_COLUMN,
  [metrics.USER_REACH]: panelFeatures.SHOW_USER_REACH_COLUMN,
};

const creativeTypes = {
  DPA: "DPA",
  FB: "FB",
  GIF: "GIF",
  HTML: "HTML",
  JPG: "JPG",
  PNG: "PNG",
  SWF: "SWF",
  VM: "VM",
  VM2: "VM2",
  VM2_CONTAINER: "VM2_CONTAINER",
  VM_AUDIO: "VM_AUDIO",
  VM_NATIVE: "VM_NATIVE",
  VM_VIDEO: "VM_VIDEO",
  YANDEX: "YANDEX",
};

const mfaMethods = {
  TOTP: "totp",
};
const mfaMethodsPriority = [mfaMethods.TOTP];
const mfaMethodToUrl = {
  [mfaMethods.TOTP]: "totp",
};

export default {
  advertiserStatus,
  adsetPlacements,
  deviceTypes,
  appCodes,
  momentDateFormat,
  colors,
  defaultConversionsCountConvention,
  userSegmentOptions,
  topHostsRankedByOptions,
  userSegmentColors,
  panelFeatures,
  conversionsFeatures,
  metrics,
  nonAdditiveMetrics,
  conversionsRelatedMetrics,
  metricsFeatures,
  creativeTypes,
  campaignTypes,
  mfaMethods,
  mfaMethodsPriority,
  mfaMethodToUrl,
  metricsByCampaignType,
};
