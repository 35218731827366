import PropTypes from "prop-types";
import React from "react";

import KendoWindow from "components/common/kendo/KendoWindow";
import { ApiErrorForbidden, ApiErrorNotFound, ApiErrorTooManyRequests } from "lib/errors";

import DashboardBtn from "./DashboardBtn";

import "./ErrorWindow.scss";

const kendoWindowConfig = {
  appendTo: "#fatal-error-window",
  title: false,
  width: 900,
  height: 420,
  resizable: false,
  draggable: false,
};

function errorToMessage(error) {
  if (error instanceof ApiErrorForbidden) {
    return "You don't have permission to perform this action.";
  }
  if (error instanceof ApiErrorNotFound) {
    return "Seems like you're looking for something that doesn't exist.";
  }
  if (error instanceof ApiErrorTooManyRequests) {
    return "Too many requests, please try again later.";
  }
  return "We're working on getting this fixed as soon as we can!";
}

function FatalErrorWindow({ error }) {
  const message = errorToMessage(error);
  const messages = Array.isArray(message) ? message : [message];

  return (
    <div id="fatal-error-window">
      <KendoWindow
        onWidgetReady={(kendoWindow) => {
          kendoWindow.center().open();
        }}
        config={kendoWindowConfig}
      >
        <h1>Whoops!</h1>
        <p>Looks like something went wrong.</p>

        {messages.map((msg, idx) => (
          <p key={`p${String(idx)}`}>{msg}</p>
        ))}

        <DashboardBtn error={error} />
      </KendoWindow>
    </div>
  );
}

FatalErrorWindow.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export default FatalErrorWindow;
