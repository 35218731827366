import * as Sentry from "@sentry/browser";

export function init(options) {
  Sentry.init(options);
}

export function identify(userData) {
  Sentry.setUser(userData);
}

export function logException(exception, context) {
  Sentry.captureException(exception, context);
}

export function logMessage(message, context) {
  Sentry.captureMessage(message, context);
}

export default { init, identify, logException, logMessage };
